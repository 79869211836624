import { z } from "zod";

const schema = z.object({
  Id: z.string(),
  Name: z.string().optional(),
  Status: z.string().optional(),
  LastModifiedDate: z.date().optional(),
  CreationDate: z.date().optional(),
});

export const UserPool = schema;
export type UserPool = z.infer<typeof schema>;

export namespace UserPool {
  export type In = z.input<typeof schema>;
}
