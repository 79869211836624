import { IconButton } from "$src/components/icon-button/icon-button";
import { Slideout } from "$src/components/slideout/slideout";
import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/use-analytics";
import { useLogout } from "$src/lib/auth";
import { cx } from "$src/lib/utils";
import { media } from "$src/styles";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Calculator,
  FileText,
  Flame,
  FlaskConical,
  HelpCircle,
  KeyRound,
  LineChart,
  LogOut,
  Milestone,
  Settings,
} from "lucide-react";
import { type ComponentProps, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useHasTrainingGroundAccess } from "../../queries/training-ground";
import { useAdminSettings } from "../../stores/use-admin-settings";
import { ControlledDropdown, DropdownSection } from "../dropdown/dropdown";
import { SettingsMenuItem } from "./lib/settings-menu-item";
import styles from "./settings-dropdown.module.css";

/**
 * @component
 * Dropdown for the settings button
 */
export const SettingsDropdown = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  const [open, setOpen] = useState(false);
  const { data: hasTrainingGroundAccess, loading } =
    useHasTrainingGroundAccess();
  const impersonateAnonymous = useAdminSettings(
    (s) => s.settings.impersonateAnonymous,
  );
  const { user } = useAmplifyAuth();
  const logout = useLogout();
  const isTablet = useMediaQuery(media.tablet);
  const El = isTablet === true ? ControlledDropdown : Slideout;
  const trigger = useRef(null);
  const analytics = useAnalytics();
  const { mfa } = useFlags();
  const toggleDropdown = () => {
    setOpen((previousState) => !previousState);
  };

  useEffect(() => {
    open && analytics?.track(AnalyticsEvents.OpenSettings);
  }, [open, analytics]);

  const settingsButton = (
    <IconButton
      className={cx(styles["button"], open && styles.active)}
      tip={!open ? "Settings" : undefined}
      ref={trigger}
      active={open}
      onClick={toggleDropdown}
      data-testid="settings-dropdown-trigger"
      icon={Settings}
      large
    />
  );

  return (
    <>
      {isTablet !== true && settingsButton}
      <El
        open={open}
        position="top"
        label={isTablet === true ? settingsButton : undefined}
        {...(isTablet === false ? { triggerRef: trigger } : {})}
        align="left"
        onChange={setOpen as any}
        className={cx(styles.dropdown, styles.new, className)}
        chevronVisible={false}
        {...props}
      >
        <SettingsMenuItem
          icon={FileText}
          label={"Survey details"}
          href="/surveydetails"
          onClick={() => setOpen(false)}
        />
        <SettingsMenuItem
          icon={Milestone}
          label={"Milestones"}
          href="/milestones"
          onClick={() => setOpen(false)}
        />
        {(user?.attributes["custom:isadmin"] === "Y" ||
          user?.attributes["custom:client_admin"] === "Y") && (
          <SettingsMenuItem
            icon={Settings}
            label={"Account settings"}
            href="/settings"
            onClick={() => setOpen(false)}
          />
        )}
        {mfa === true && (
          <SettingsMenuItem
            icon={KeyRound}
            label={"Setup & security"}
            href="/settings/mfa"
            onClick={() => setOpen(false)}
          />
        )}
        <DropdownSection>
          <SettingsMenuItem
            icon={HelpCircle}
            label="Help center"
            href="https://help.gotracksuit.com/kb/en"
            onClick={() => setOpen(false)}
          />
          <SettingsMenuItem
            icon={Flame}
            label={"Tracksuit trailblazers"}
            href="https://engage.gotracksuit.com/join-the-trailblazers"
            onClick={() => setOpen(false)}
          />
        </DropdownSection>

        <DropdownSection label="Tools">
          <SettingsMenuItem
            icon={LineChart}
            label={"Industry benchmarks"}
            href="https://www.gotracksuit.com/brand-benchmarks-tool"
            onClick={() => setOpen(false)}
          />
          <SettingsMenuItem
            icon={Calculator}
            label={"Budget calculator"}
            href="https://www.gotracksuit.com/marketing-budget-calculator"
            onClick={() => setOpen(false)}
          />
          {((hasTrainingGroundAccess === true && loading === false) ||
            (!impersonateAnonymous && user?.attributes["custom:isadmin"])) && (
            <SettingsMenuItem
              icon={FlaskConical}
              label={"Tracksuit Training Ground"}
              href="/playground"
              onClick={() => setOpen(false)}
            />
          )}
        </DropdownSection>

        <DropdownSection>
          <SettingsMenuItem icon={LogOut} label={"Logout"} onClick={logout} />
        </DropdownSection>
      </El>
    </>
  );
};
