import { z } from "zod";

import * as cognito from "@tracksuit/cognito/models";

import { Action } from "./actions";

export const InviteUserResult = z.object({
  user: cognito.User,
  actions: Action.array(),
});

export type InviteUserResult = z.infer<typeof InviteUserResult>;

export namespace InviteUserResult {
  export type In = z.input<typeof InviteUserResult>;
}
