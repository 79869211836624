import _ from "lodash";
import { z } from "zod";

import { BoolString } from "./bool-string";
import { CommaSeparated, CommaSeparatedStrings } from "./comma-separated";
import { RawUserAttributes } from "./raw-user-attributes";
import * as s from "./scalars";

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_MFAOptionType.html
export const MFAOptionType = z.object({
  AttributeName: z.literal("phone_number"),
  DeliveryMedium: z.enum(["SMS", "EMAIL"]),
});

export namespace Attributes {
  const structuredUserAttributes = RawUserAttributes.extend({
    email_verified: BoolString.optional().default(false),
    "custom:isadmin": BoolString.optional().default(false),
    "custom:is_demo": BoolString.optional().default(false),
    "custom:client_admin": BoolString.optional().default(false),
    "custom:clients": CommaSeparated(s.ClientId).optional(),
    "custom:feature_flags": CommaSeparatedStrings.optional(),
    "custom:onboard_uuids": CommaSeparated(s.OnboardingId).optional(),
  });

  const kvpArray = z
    .object({ Name: z.string(), Value: z.string() })
    .array()
    .transform((array) =>
      _(array)
        .groupBy("Name")
        .mapValues(([first]) => first?.Value)
        .value(),
    );

  export const parser = structuredUserAttributes.or(
    kvpArray.pipe(structuredUserAttributes),
  );
}

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html
const schema = z.object({
  UserAttributes: Attributes.parser.optional(),
  Attributes: Attributes.parser.optional(),
  Enabled: z.boolean(),
  MFAOptions: MFAOptionType.array().optional(),
  UserCreateDate: z.coerce.date(),
  UserLastModifiedDate: z.coerce.date().optional(),
  UserStatus: s.UserStatus,
  Username: s.Username,
});

export const CognitoUser = schema;
export type CognitoUser = z.infer<typeof schema>;

export namespace CognitoUser {
  export type In = z.input<typeof schema>;
}
