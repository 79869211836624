import { z } from "zod";

import * as shared from "@tracksuit/schemas/shared";

import { Account } from "./account";
import { AccountBrand } from "./account-brand";
import { Brand } from "./brand";
import { BrandCatGeo } from "./brand-cat-geo";
import { CategoryGeography } from "./category-geography";
import { TabsConfiguration } from "./tabs-configuration";

const schema = z.object({
  account: Account,
  brand: Brand.pick({ id: true, name: true }),
  category: shared.Category,
  categoryGeography: CategoryGeography,
  config: z.object({
    enabledTabs: TabsConfiguration,
    includeIncompleteMonths: z.boolean(),
    unpromptedAwarenessEnabled: z.boolean(),
  }),
  geography: shared.Geography,
  accountBrand: AccountBrand.pick({ id: true }),
  brandCatGeo: BrandCatGeo.pick({ id: true }),
  accountStartDate: z.date(),
  trackerInfo: z.object({
    trackerTopLevel: z.enum(["NATIONAL", "REGIONAL"]),
    isTrackingEnabled: z.boolean(),
  }),
});

export const AccountBrandMetadata = schema;
export type AccountBrandMetadata = z.infer<typeof schema>;

export namespace AccountBrandMetadata {
  export type In = z.input<typeof schema>;
}
