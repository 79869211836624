import type { DataRepresentation } from "@tracksuit/frontend/schemas";

export const FUNNEL_QUESTIONS = [
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "USAGE",
  "PREFERENCE",
];

/**
 * Minimal replacement for classnames and clsx
 */
export const cx = (...args: (string | boolean | undefined | null)[]) => {
  return args.filter(Boolean).join(" ");
};

/**
 * Format percentages
 * Not tested since it's just a configured Intl instance
 */
/* v8 ignore next */
export const percentageFormatter = Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
});

/**
 * Round numbers to textual representations
 */
export const roundNumber = (number: number, isApprox?: boolean) => {
  if (Math.abs(number) < 1000) {
    return (isApprox ? "~" : "") + String(Math.round(number));
  }

  return (
    (isApprox ? "~" : "") +
    (Math.abs(number) < 1000000
      ? `${parseFloat((number / 1000).toFixed(0))}K`
      : `${parseFloat((number / 1000000).toFixed(1))}M`)
  );
};

/**
 * Formats based on data representation
 */
export const formatNumberRepresentation = (
  value: number,
  format: DataRepresentation,
) => {
  return format === "percentage"
    ? percentageFormatter.format(value)
    : roundNumber(value);
};

/**
 * Sort by question type
 */
export const sortQuestionTypes = (a: any, b: any) => {
  return (
    FUNNEL_QUESTIONS.indexOf(a.questionType) -
    FUNNEL_QUESTIONS.indexOf(b.questionType)
  );
};

/**
 * Check for external URLs
 */
export const isExternalURL = (url: string) => {
  return url.startsWith("http");
};

/**
 * Utility to add all items in an array to a Set
 */
export const addAllToSet = <T>(array: T[], set: Set<T>): Set<T> => {
  array.forEach((item) => set.add(item));
  return set;
};

/**
 * Formats a location into two characters
 */
export const formatBrandGeography = (brandGeography: string): string => {
  if (brandGeography === "Auckland") {
    return "AKL";
  }

  const words = brandGeography.split(" ");

  if (words.length === 1) {
    const firstTwoLetters = (words[0]?.slice(0, 2) || "").toUpperCase();
    return firstTwoLetters;
  }
  const acronym = words.map((word) => word[0]?.toUpperCase()).join("");
  return acronym;
};

/**
 * Formats a word into its possessive form
 */
export const formatPossessive = (word: string) => {
  return word.endsWith("s") ? `${word}'` : `${word}'s`;
};
