import { OnboardingInfo } from "@tracksuit/user-management/models";
import { z } from "zod";

export const UpsertOnboardingInfoFormData = OnboardingInfo.omit({
  sub: true,
}).refine(
  ({ jobArea, otherJobArea }) =>
    !jobArea || jobArea !== "Other" || otherJobArea,
  {
    path: ["otherJobArea"],
    message: "Required",
  },
);

export type UpsertOnboardingInfoFormData = z.infer<
  typeof UpsertOnboardingInfoFormData
>;
