import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorPage } from "./components/error/error";
import { loadConfig } from "./stores/use-config";

(async () => {
  const config = await loadConfig();
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config?.LAUNCHDARKLY_SDK_CLIENT as string,
    context: {
      kind: "user",
      key: "anonymous",
      environment: config?.ENVIRONMENT,
    },
    options: {
      streaming: false,
    },
  });

  if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
    const { setupWorker } = await import("msw/browser");
    const worker = setupWorker();
    (await import("$src/routes/survey/__mocks__/mocks")).default(worker);
    await worker.start({ onUnhandledRequest: "bypass" });
  }

  const { Router } = await import("./routes/router");

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <LDProvider>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Router />
      </ErrorBoundary>
    </LDProvider>,
  );
})();
