import { z } from "zod";

import * as shared from "@tracksuit/schemas/shared";

export const Brand = z.object({
  id: shared.BrandId,
  name: shared.BrandName,
  category: shared.Category,
  geography: shared.Geography,
});

export type Brand = z.infer<typeof Brand>;

export type BrandLike = z.input<typeof Brand>;

export {
  BrandId,
  BrandName,
  Category,
  Geography,
} from "@tracksuit/schemas/shared";
