import { z } from "zod";

const schema = z.boolean().or(
  z.string().transform((value, ctx) => {
    switch (value.toLowerCase()) {
      case "true": {
        return true;
      }
      case "false": {
        return false;
      }
      case "y": {
        return true;
      }
      case "n": {
        return false;
      }
      default: {
        ctx.addIssue({
          code: "custom",
          message: "Value cannot be parsed as a boolean string",
        });
        return z.NEVER;
      }
    }
  }),
);

export const BoolString = schema;
export type BoolString = z.infer<typeof schema>;

export namespace BoolString {
  export type In = z.input<typeof schema>;
}
