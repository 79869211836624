import { z } from "zod";

import { SubjectId } from "@tracksuit/cognito/models";

export const OnboardingInfo = z.object({
  sub: SubjectId,
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  country: z.string().min(1),
  city: z.string().min(1),
  marketingUpdatesOptIn: z.boolean(),
  jobArea: z.string().min(1),
  otherJobArea: z.string(),
  agency: z.boolean(),
  brandTrackingConfidence: z.number().int().min(0).max(3),
});

export type OnboardingInfo = z.infer<typeof OnboardingInfo>;
export namespace OnboardingInfo {
  export type In = z.input<typeof OnboardingInfo>;
}
