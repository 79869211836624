import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/use-account";

export const useHasTrainingGroundAccess = () => {
  const account = useAccount((s) => s.active);

  const { data, isLoading: loading } =
    apiQueryClient.trainingGround.hasAccess.useQuery(
      {
        accountBrandId: account?.accountBrandId as number,
      },
      {
        enabled: !!account,
      },
    );

  return {
    data,
    loading,
  };
};
