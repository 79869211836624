import { z } from "zod";

import * as scm from "@tracksuit/schemas/shared";

const schema = z.object({
  brandCategoryGeographyId: scm.BrandCategoryGeographyId,
  id: scm.BrandId,
  name: scm.BrandName,
});

export const BrandCompetitor = schema;
export type BrandCompetitor = z.infer<typeof schema>;

export namespace BrandCompetitor {
  export type In = z.input<typeof schema>;
}
