import { z } from "zod";

import { Email } from "@tracksuit/fundamental-types";

import { SubjectId } from "./scalars";

const yesOrNo = z.enum(["Y", "N"]);

export const RawUserAttributes = z
  .object({
    sub: SubjectId,
    email: Email,
    email_verified: z.string().optional(),
    "custom:isadmin": yesOrNo.optional(),
    "custom:is_demo": yesOrNo.optional(),
    "custom:client_admin": yesOrNo.optional(),
    "custom:clients": z.string().optional(),
    "custom:feature_flags": z.string().optional(),
    "custom:onboard_uuids": z.string().optional(),
  })
  .passthrough();

export type RawUserAttributes = z.infer<typeof RawUserAttributes>;
export namespace RawUserAttributes {
  export type In = z.input<typeof RawUserAttributes>;
}
