import { z } from "zod";

const tabNames = [
  "funnel",
  "imagery",
  "investigation",
  "profile",
  "statements",
  "usage",
  "timeline",
] as const;

type TabNames = (typeof tabNames)[number];
type SettingNames = `${TabNames}Enabled`;

export const TabNames = z.enum(tabNames);

export const TabsConfiguration = z.object({
  funnelEnabled: z.boolean(),
  imageryEnabled: z.boolean(),
  investigationEnabled: z.boolean(),
  profileEnabled: z.boolean(),
  statementsEnabled: z.boolean(),
  usageEnabled: z.boolean(),
  timelineEnabled: z.boolean(),
});

export type TabsConfiguration = Record<SettingNames, boolean>;
