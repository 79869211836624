import { z } from "zod";

import * as shared from "@tracksuit/schemas/shared";

import { Account } from "./account";
import { Brand } from "./brand";

const schema = z.object({
  id: shared.AccountBrandId,
  account: Account,
  brand: Brand,
});

export const AccountBrand = schema;
export type AccountBrand = z.infer<typeof schema>;

export namespace AccountBrand {
  export type In = z.input<typeof schema>;
}

export { AccountBrandId } from "@tracksuit/schemas/shared";
