import { z } from "zod";

import { PatUUID } from "./scalars";

const schema = z.object({
  revoked: PatUUID,
});

export const AccessTokenRevocation = schema;
export type AccessTokenRevocation = z.infer<typeof schema>;

export namespace AccessTokenRevocation {
  export type In = z.input<typeof schema>;
}
