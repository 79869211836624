import { z } from "zod";

import * as scalars from "./scalars";
import { User } from "./user";

const schema = z.object({
  paginationToken: scalars.PaginationToken.optional(),
  users: User.array(),
});

export const PaginatedUsers = schema;
export type PaginatedUsers = z.infer<typeof schema>;

export namespace PaginatedUsers {
  export type In = z.input<typeof schema>;
}
