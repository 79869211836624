import { z } from "zod";

import { Email } from "@tracksuit/fundamental-types";

import * as s from "./scalars";

const schema = z.object({
  clients: s.ClientId.array().optional().default([]),
  createdAt: z.date(),
  email: Email,
  enabled: z.boolean(),
  isAdmin: z.boolean().optional().default(false),
  isClientAdmin: z.boolean().optional().default(false),
  isEmailVerified: z.boolean().optional().default(false),
  status: s.UserStatus,
  sub: s.SubjectId,
  updatedAt: z.date().optional(),
  username: s.Username,
});

export const User = schema;
export type User = z.infer<typeof schema>;

export namespace User {
  export type In = z.input<typeof schema>;
}
