import { z } from "zod";

import { SubjectId } from "@tracksuit/cognito/models";

import { Action } from "./actions";

export const Grant = z.object({
  principal: SubjectId,
  action: Action,
});

export type Grant<T extends Action = Action> = z.infer<typeof Grant> & {
  action: T;
};

export namespace Grant {
  export type In = z.input<typeof Grant>;
}
