/* eslint-disable import/export */
import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const AccountBrandId = IntoNat.brand("AccountBrandId");

export type AccountBrandId = z.infer<typeof AccountBrandId>;
export namespace AccountBrandId {
  export type In = z.input<typeof AccountBrandId>;
}
