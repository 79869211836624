import { z } from "zod";

import * as shared from "@tracksuit/schemas/shared";

const schema = z.object({
  id: shared.CategoryGeographyId,
});

export const CategoryGeography = schema;
export type CategoryGeography = z.infer<typeof schema>;

export namespace CategoryGeography {
  export type In = z.input<typeof schema>;
}

export { CategoryGeographyId } from "@tracksuit/schemas/shared";
