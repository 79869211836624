import { z } from "zod";

import { AccountBrandId } from "@tracksuit/metadata/models";

export const Operations = z.enum([
  "ViewDashboard",
  "InviteDashboardAccess",
  "RevokeDashboardAccess",
  "ManageDashboard",
  "DelegateManageDashboard",
]);

export type Operations = z.infer<typeof Operations>;

export namespace Actions {
  export const ViewDashboard = z.object({
    operation: z.literal(Operations.enum.ViewDashboard),
    accountBrand: AccountBrandId,
  });

  export type ViewDashboard = z.infer<typeof ViewDashboard>;

  export const InviteDashboardAccess = z.object({
    operation: z.literal(Operations.enum.InviteDashboardAccess),
    accountBrand: AccountBrandId,
  });

  export type InviteDashboardAccess = z.infer<typeof InviteDashboardAccess>;

  export const RevokeDashboardAccess = z.object({
    operation: z.literal(Operations.enum.RevokeDashboardAccess),
    accountBrand: AccountBrandId,
  });

  export type RevokeDashboardAccess = z.infer<typeof RevokeDashboardAccess>;

  export const ManageDashboard = z.object({
    operation: z.literal(Operations.enum.ManageDashboard),
    accountBrand: AccountBrandId,
  });

  export type ManageDashboard = z.infer<typeof ManageDashboard>;

  export const DelegateManageDashboard = z.object({
    operation: z.literal(Operations.enum.DelegateManageDashboard),
    accountBrand: AccountBrandId,
  });

  export type DelegateManageDashboard = z.infer<typeof DelegateManageDashboard>;
}

/** Represents a user's entitlement to perform an operation. */
export const Action = z.discriminatedUnion("operation", [
  Actions.ViewDashboard,
  Actions.InviteDashboardAccess,
  Actions.ManageDashboard,
  Actions.RevokeDashboardAccess,
  Actions.DelegateManageDashboard,
]);

export type Action = z.infer<typeof Action>;

export namespace Action {
  export type In = z.input<typeof Action>;
}
