import { z } from "zod";

import { Email, FromStringified } from "@tracksuit/fundamental-types";

import { Action } from "./actions";

export const Principal = z.object({
  email: Email,
  grants: FromStringified.pipe(Action.array().or(z.literal("UNRESTRICTED"))),
});

export type Principal = z.infer<typeof Principal>;
export namespace Principal {
  export type In = z.input<typeof Principal>;
}
