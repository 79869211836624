/* eslint-disable import/export */
import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const AccountId = IntoNat.brand("AccountId");

export type AccountId = z.infer<typeof AccountId>;
export namespace AccountId {
  export type In = z.input<typeof AccountId>;
}

export const AccountName = z.string().brand("AccountName");

export type AccountName = z.infer<typeof AccountName>;
export namespace AccountName {
  export type In = z.input<typeof AccountName>;
}

export const Account = z.object({
  accountId: AccountId,
  accountName: AccountName,
});

export type Account = z.infer<typeof Account>;
export namespace Account {
  export type In = z.input<typeof Account>;
}
