import { z } from "zod";

import * as s from "./scalars";

const schema = z.object({
  id: s.PatUUID,
  sub: s.SubjectId,
  name: z.string(),
  createdAt: z.instanceof(Date),
  revokedAt: z.instanceof(Date).nullable(),
  expiresAt: z.instanceof(Date),
});

export const GrantMetadata = schema;
export type GrantMetadata = z.infer<typeof schema>;

export namespace GrantMetadata {
  export type In = z.input<typeof schema>;
}
