import { z } from "zod";

import { PatUUID } from "./scalars";

const schema = z.object({
  deleted: PatUUID,
});

export const AccessTokenDeletion = schema;
export type AccessTokenDeletion = z.infer<typeof schema>;

export namespace AccessTokenDeletion {
  export type In = z.input<typeof schema>;
}
