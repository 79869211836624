import { z } from "zod";

export const questionType = z.enum([
  "QUALIFYING",
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "PREFERENCE",
  "USAGE",
  "BRAND_ASSOCIATIONS",
  "DEMOGRAPHIC",
  "CUSTOM_QUESTION",
]);

export type QuestionType = z.infer<typeof questionType>;

export const timelineConversionIds = z.enum([
  "PROMPTED_AWARENESS-CONSIDERATION",
  "CONSIDERATION-PREFERENCE",
]);

export type TimelineConversionIds = z.infer<typeof timelineConversionIds>;

export const funnelQuestionType = questionType.exclude([
  "QUALIFYING",
  "BRAND_ASSOCIATIONS",
  "DEMOGRAPHIC",
  "CUSTOM_QUESTION",
]);

export type FunnelQuestionType = z.infer<typeof funnelQuestionType>;

export const statementsQuestionType = questionType.exclude([
  "BRAND_ASSOCIATIONS",
  "DEMOGRAPHIC",
  "CUSTOM_QUESTION",
  "UNPROMPTED_AWARENESS",
]);

export type StatementsQuestionType = z.infer<typeof statementsQuestionType>;

export const conversionQuestionType = questionType.exclude([
  "QUALIFYING",
  "BRAND_ASSOCIATIONS",
  "DEMOGRAPHIC",
  "CUSTOM_QUESTION",
  "UNPROMPTED_AWARENESS",
]);

export type ConversionQuestionType = z.infer<typeof conversionQuestionType>;

export const questionSubType = z.enum([
  "AGE_GPS",
  "ETHN_AU",
  "REGN_NZ",
  "ETHN_UK",
  "REGN_US_W",
  "REGN_AKL",
  "GENDER",
  "MINDSET2",
  "ETHN_LN",
  "LOCATION_CODE",
  "HH_INC",
  "ETHN_US",
  "HH_STR_SCHOOL",
  "BORN_AU",
  "IN_SEC",
  "REGN_AU",
  "REGN_UK",
  "NZ_BORN",
]);
