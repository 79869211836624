import { z } from "zod";

import * as scm from "@tracksuit/schemas/shared";

const schema = z.object({
  id: scm.BrandCategoryGeographyId,
  category: scm.Category,
  geography: scm.Geography,
  brand: scm.Brand,
});

export const BrandCatGeo = schema;
export type BrandCatGeo = z.infer<typeof schema>;

export namespace BrandCatGeo {
  export type In = z.input<typeof schema>;
}

export { BrandCategoryGeographyId } from "@tracksuit/schemas/shared";
