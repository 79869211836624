import { z } from "zod";

import * as shared from "@tracksuit/schemas/shared";

export const Account = z.object({
  id: shared.AccountId,
  name: shared.AccountName,
});

export type Account = z.infer<typeof Account>;

export type AccountLike = z.input<typeof Account>;

export { AccountId, AccountName } from "@tracksuit/schemas/shared";
