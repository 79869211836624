import { z } from "zod";

const splitOnCommas = (str: string) =>
  str.split(/[ ]*,[ ]*/g).filter((it) => it.length > 0);

export const CommaSeparatedStrings = z.string().transform(splitOnCommas);

export const CommaSeparated = <T extends z.ZodSchema>(s: T) =>
  CommaSeparatedStrings.pipe(s.array());

export type CommaSeparatedStrings = z.infer<typeof CommaSeparatedStrings>;

export namespace CommaSeparatedStrings {
  export type In = z.input<typeof CommaSeparatedStrings>;
}
