import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const AccessToken = z.string().brand("cognito/access-token");
export type AccessToken = z.infer<typeof AccessToken>;
export namespace AccessToken {
  export type In = z.input<typeof AccessToken>;
}

export const UserStatus = z.enum([
  "UNCONFIRMED",
  "CONFIRMED",
  "ARCHIVED",
  "COMPROMISED",
  "UNKNOWN",
  "RESET_REQUIRED",
  "FORCE_CHANGE_PASSWORD",
]);

export type UserStatus = z.infer<typeof UserStatus>;
export namespace UserStatus {
  export type In = z.input<typeof UserStatus>;
}

export const ClientId = IntoNat.brand("ClientId");
export type ClientId = z.infer<typeof ClientId>;
export namespace ClientId {
  export type In = z.input<typeof ClientId>;
}

export const SubjectId = z.string().brand("cognito/sub");
export type SubjectId = z.infer<typeof SubjectId>;
export namespace SubjectId {
  export type In = z.input<typeof SubjectId>;
}

export const OnboardingId = z.string().uuid().brand("OnboardingId");
export type OnboardingId = z.infer<typeof OnboardingId>;
export namespace OnboardingId {
  export type In = z.input<typeof OnboardingId>;
}

export const Username = z.string().min(1).max(128).brand("cognito/username");
export type Username = z.infer<typeof Username>;
export namespace Username {
  export type In = z.input<typeof Username>;
}

export const PatUUID = z.string().uuid().brand("cognito/pat-uuid");
export type PatUUID = z.infer<typeof PatUUID>;
export namespace PatUUID {
  export type In = z.input<typeof PatUUID>;
}

export const PaginationToken = z
  .string()
  .min(1)
  .brand("cognito/pagination-token");

export type PaginationToken = z.infer<typeof PaginationToken>;
export namespace PaginationToken {
  export type In = z.input<typeof PaginationToken>;
}
