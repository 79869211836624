import { useAccount } from "$src/stores/use-account";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

export const useNav = () => {
  const account = useAccount((s) => s.active);
  const { storyPage } = useFlags();
  const [nav, setNav] = useState<
    { label: string; path: string; beta?: boolean }[]
  >([]);

  useEffect(() => {
    if (!account?.accountBrandId) {
      return;
    }
    setNav(
      [
        storyPage && {
          label: "Coach's notes",
          path: "/coachs-notes",
          beta: true,
        },
        {
          label: "Funnel",
          path: "/funnel",
        },
        {
          label: "Conversion",
          path: "/conversion",
        },
        account.featuresConfig.imageryEnabled && {
          label: "Imagery",
          path: "/imagery",
        },
        account.featuresConfig.statementsEnabled && {
          label: "Statements",
          path: "/statements",
        },
        account.featuresConfig.profileEnabled && {
          label: "Profile",
          path: "/profile",
        },
      ].filter(Boolean) as { label: string; path: string }[],
    );
  }, [
    account?.accountBrandId,
    account?.featuresConfig.imageryEnabled,
    account?.featuresConfig.profileEnabled,
    account?.featuresConfig.statementsEnabled,
    storyPage,
  ]);

  return nav;
};
