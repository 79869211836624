import { z } from "zod";

import { AccessToken } from "./scalars";

const schema = z.object({
  accessToken: AccessToken,
  expiresAt: z.instanceof(Date),
});

export const AccessTokenCreation = schema;
export type AccessTokenCreation = z.infer<typeof schema>;

export namespace AccessTokenCreation {
  export type In = z.input<typeof schema>;
}
